import uniqBy from 'lodash/uniqBy';
import { fg } from '@atlassian/jira-feature-gating';
import {
	CATEGORY_TYPE,
	FIX_VERSIONS_TYPE,
	GOALS_CF_TYPE,
	PARENT_FIELD_TYPE,
	SPRINT_TYPE,
	TEAMS_PLATFORM_CF_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import {
	transformIssueTypes,
	transformPriorities,
	transformStatuses,
	transformDefaultField,
	transformUsers,
	transformSprints,
	transformValuesData,
} from '../../common/utils/transform-field-values/index.tsx';
import type {
	IssueTypeFieldValues,
	PriorityFieldValues,
	StatusFieldValues,
	DefaultFieldValues,
	UserFieldValues,
	TransformedInitialData,
} from '../../common/utils/transform-field-values/types.tsx';
import type { HydratedValues } from './types.tsx';

const transformHydratedValuesOld = (data: HydratedValues): TransformedInitialData => {
	const transformedHydratedData: TransformedInitialData = {
		labels: { totalCount: 0, options: [] },
		components: { totalCount: 0, options: [] },
		priority: { totalCount: 0, options: [] },
		users: { totalCount: 0, options: [] },
		issuetype: { totalCount: 0, options: [] },
		status: { totalCount: 0, options: [] },
		[CATEGORY_TYPE]: { totalCount: 0, options: [] },
		[SPRINT_TYPE]: { totalCount: 0, options: [] },
	};

	Object.keys(data).forEach((key) => {
		if (data[key] && data[key].edges && Array.isArray(data[key].edges)) {
			switch (key) {
				case 'issuetype':
					transformedHydratedData[key] = {
						totalCount: 0,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						options: transformIssueTypes(data[key] as IssueTypeFieldValues),
					};
					return;
				case 'priority':
					transformedHydratedData[key] = {
						totalCount: 0,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						options: transformPriorities(data[key] as PriorityFieldValues),
					};
					return;
				case 'status':
					transformedHydratedData[key] = {
						totalCount: 0,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						options: transformStatuses(data[key] as StatusFieldValues),
					};
					return;
				case 'labels':
					transformedHydratedData[key] = {
						totalCount: 0,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						options: transformDefaultField(data[key] as DefaultFieldValues),
					};
					return;
				case 'component':
					transformedHydratedData.components = {
						totalCount: 0,
						// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
						options: transformDefaultField(data[key] as DefaultFieldValues),
					};
					return;
				default:
					if (data[key].edges.some((item) => item.node && 'user' in item.node)) {
						transformedHydratedData.users = {
							totalCount: 0,
							options: uniqBy(
								[
									// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
									...transformUsers(data[key] as UserFieldValues),
									...(transformedHydratedData.users?.options ?? []),
								],
								({ value }) => value,
							),
						};
					} else if (data[key].edges.some((item) => item.node && 'sprint' in item.node)) {
						transformedHydratedData[SPRINT_TYPE] = {
							totalCount: 0,
							// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
							options: transformSprints(data[key] as DefaultFieldValues),
						};
					}
			}
		}
	});

	return transformedHydratedData;
};

export const transformHydratedValues = (data: HydratedValues): TransformedInitialData => {
	if (fg('jsw_list_view_-_all_the_fields')) {
		return transformValuesData(data);
	}
	return transformHydratedValuesOld(data);
};

export const mergeHydratedValues = (
	initialData: TransformedInitialData,
	hydratedValues: TransformedInitialData,
) => ({
	labels: {
		totalCount: initialData.labels.totalCount,
		options: uniqBy(
			[...hydratedValues.labels.options, ...initialData.labels.options],
			({ value }) => value,
		),
	},
	components: {
		totalCount: initialData.components.totalCount,
		options: uniqBy(
			[...hydratedValues.components.options, ...initialData.components.options],
			({ value }) => value,
		),
	},
	priority: {
		totalCount: initialData.priority.totalCount,
		options: uniqBy(
			[...hydratedValues.priority.options, ...initialData.priority.options],
			({ value }) => value,
		),
	},
	users: {
		totalCount: initialData.users.totalCount,
		options: uniqBy(
			[...hydratedValues.users.options, ...initialData.users.options],
			({ value }) => value,
		),
	},
	issuetype: {
		totalCount: initialData.issuetype.totalCount,
		options: uniqBy(
			[...hydratedValues.issuetype.options, ...initialData.issuetype.options],
			({ value }) => value,
		),
	},
	status: {
		totalCount: initialData.status.totalCount,
		options: uniqBy(
			[...hydratedValues.status.options, ...initialData.status.options],
			({ value }) => value,
		),
	},
	// hydrate values endpoint doesn't support categories
	[CATEGORY_TYPE]: initialData[CATEGORY_TYPE],
	[SPRINT_TYPE]: {
		totalCount: initialData[SPRINT_TYPE].totalCount,
		options: uniqBy(
			[...hydratedValues[SPRINT_TYPE].options, ...initialData[SPRINT_TYPE].options],
			({ value }) => value,
		),
	},
	[FIX_VERSIONS_TYPE]: {
		totalCount: initialData[FIX_VERSIONS_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[FIX_VERSIONS_TYPE]?.options || []), // TODO: Remove the optional once we clean-up 'jsw_list_view_-_all_the_fields'
				...(initialData[FIX_VERSIONS_TYPE]?.options || []), // TODO: Remove the optional once we clean-up 'jsw_list_view_-_all_the_fields'
			],
			({ value }) => value,
		),
	},
	[PARENT_FIELD_TYPE]: {
		totalCount: initialData[PARENT_FIELD_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[PARENT_FIELD_TYPE]?.options || []), // TODO: Remove the optional once we clean-up 'jsw_list_view_-_all_the_fields'
				...(initialData[PARENT_FIELD_TYPE]?.options || []), // TODO: Remove the optional once we clean-up 'jsw_list_view_-_all_the_fields'
			],
			({ value }) => value,
		),
	},
	[GOALS_CF_TYPE]: {
		totalCount: initialData[GOALS_CF_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[GOALS_CF_TYPE]?.options || []), // TODO: Remove the optional once we clean-up native_goals_integration_on_list_view
				...(initialData[GOALS_CF_TYPE]?.options || []), // TODO: Remove the optional once we clean-up native_goals_integration_on_list_view
			],
			({ value }) => value,
		),
	},
	[TEAMS_PLATFORM_CF_TYPE]: {
		totalCount: initialData[TEAMS_PLATFORM_CF_TYPE]?.totalCount || 0,
		options: uniqBy(
			[
				...(hydratedValues[TEAMS_PLATFORM_CF_TYPE]?.options || []), // TODO: Remove the optional once we clean-up 'jsw_list_view_-_all_the_fields'
				...(initialData[TEAMS_PLATFORM_CF_TYPE]?.options || []), // TODO: Remove the optional once we clean-up 'jsw_list_view_-_all_the_fields'
			],
			({ value }) => value,
		),
	},
});

import React from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type ColumnDeleteModalType from './main.tsx';
import type { Props } from './main.tsx';

const ColumnDeleteModal = lazyForPaint<typeof ColumnDeleteModalType>(
	() => import(/* webpackChunkName: "async-jwm-board-column-delete-modal" */ './main'),
);

const AsyncColumnDeleteModal = (props: Props) => (
	<ErrorBoundary
		id="async.jira-business.board.column-delete-modal"
		packageName="async.jira-business.board.column-delete-modal"
	>
		<Placeholder name="column-delete-modal" fallback={null}>
			<ColumnDeleteModal {...props} />
		</Placeholder>
	</ErrorBoundary>
);

export default AsyncColumnDeleteModal;

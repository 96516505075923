import React, { memo, useCallback, useState } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import { IconButton } from '@atlaskit/button/new';
import ShowMoreHorizontalIconOld from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import Popup, { type TriggerProps } from '@atlaskit/popup';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { expVal } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ModalContextProvider } from '@atlassian/jira-modal-context-provider/src/ModalContextProvider.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import type { BoardIssue } from '../../../../../common/types.tsx';
import { useIssueGroup } from '../../../../../controllers/issues-by-group/index.tsx';
import CardActionsMenu from './card-actions-menu/index.tsx';
import messages from './messages.tsx';
import { useIsCardCoversNew } from './useIsCardCoversNew.tsx';

export const CARD_ACTIONS_DISPLAY_VARIABLE = '--card-actions-display';

type Props = {
	issue: BoardIssue;
};

const CardActions = ({ issue }: Props) => {
	const { formatMessage } = useIntl();

	const [isOpen, setIsOpen] = useState(false);
	const openMenu = useCallback(() => setIsOpen(true), []);
	const closeMenu = useCallback(() => setIsOpen(false), []);
	const toggleMenu = useCallback(() => setIsOpen((open) => !open), []);
	const [isCardCoversNew] = useIsCardCoversNew();
	const { group } = useIssueGroup();

	const popupContent = useCallback(
		({ update }: { update: () => void }) => (
			<CardActionsMenu
				issue={issue}
				onContentResize={update}
				onClosePopup={closeMenu}
				onMenuRendered={update}
				onOpenPopup={openMenu}
			/>
		),
		[issue, closeMenu, openMenu],
	);

	const trigger = useCallback(
		(triggerProps: TriggerProps) => {
			return isVisualRefreshEnabled() ? (
				<Box
					xcss={[
						IconButtonStyles,
						isOpen && IconButtonMenuOpenStyles,
						isCardCoversNew &&
							fg('jwm_card_covers_changeboarding') &&
							highlightedActionButtonStyles,
					]}
				>
					<IconButton
						{...triggerProps}
						icon={ShowMoreHorizontalIcon}
						label={formatMessage(messages.cardActionsWithIssueName, {
							columnName: group.name,
							issueKey: issue.fields.issuekey.value,
						})}
						onClick={toggleMenu}
						testId="work-management-board.ui.board.column.card.card-actions.trigger"
					/>
				</Box>
			) : (
				<TriggerButtonWrapper>
					<StyledButton
						{...triggerProps}
						iconBefore={
							<ShowMoreHorizontalIconOld
								label={formatMessage(
									expVal('issue-terminology-refresh-m2-replace', 'isEnabled', false)
										? messages.cardActionsWithIssueNameIssueTermRefresh
										: messages.cardActionsWithIssueName,
									{
										columnName: group.name,
										issueKey: issue.fields.issuekey.value,
									},
								)}
								color={token('color.icon')}
							/>
						}
						onClick={toggleMenu}
						testId="work-management-board.ui.board.column.card.card-actions.trigger"
						isCardCoversNew={isCardCoversNew}
					/>
				</TriggerButtonWrapper>
			);
		},
		[isOpen, formatMessage, group, issue, toggleMenu, isCardCoversNew],
	);

	return (
		<ModalContextProvider>
			<Popup
				isOpen={isOpen}
				onClose={closeMenu}
				content={popupContent}
				placement="right-start"
				trigger={trigger}
			/>
		</ModalContextProvider>
	);
};

export default memo(CardActions);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TriggerButtonWrapper = styled.div({
	position: 'absolute',
	top: token('space.100', '8px'),
	right: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- Ignored via go/DSP-18766
const StyledButton = styled(Button)<{ isCardCoversNew: boolean }>({
	display: `var(${CARD_ACTIONS_DISPLAY_VARIABLE}, none)`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'&:focus, &[aria-expanded="true"]': {
		display: 'flex',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles
		boxShadow: (props) =>
			props.isCardCoversNew ? `0 0 0 2px ${token('color.border.discovery')}` : 'none',
	},
});

const IconButtonStyles = xcss({
	position: 'absolute',
	top: token('space.100', '8px'),
	right: token('space.100', '8px'),
	display: `var(${CARD_ACTIONS_DISPLAY_VARIABLE}, none)`,
});

const IconButtonMenuOpenStyles = xcss({
	display: 'block',
});

const highlightedActionButtonStyles = xcss({
	borderRadius: '8px',
	borderWidth: '2px',
	borderStyle: 'solid',
	borderColor: 'color.border.discovery',
});

import React, { type ComponentType } from 'react';
import '@atlaskit/css-reset';
import ProjectContextProvider from '@atlassian/jira-business-entity-project-provider/src/ui/project-context/index.tsx';
import { BOARD_VIEW_PREFERENCES } from '@atlassian/jira-business-preferences/src/constants.tsx';
import { ViewPreferencesProvider } from '@atlassian/jira-business-preferences/src/controllers/view-preferences-context/index.tsx';
import { SubProductUpdater } from '@atlassian/jira-spa-apps-common/src/analytics-sub-product/sub-product-updater/index.tsx';
import { AuthenticationCheck } from '@atlassian/jira-spa-apps-common/src/interceptors/authentication/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import Board from '@atlassian/jira-work-management-board/src/index.tsx';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type JiraBusinessBoardAppType = Record<any, any>;

const JiraBusinessBoardApp: ComponentType<JiraBusinessBoardAppType> = () => (
	<UFOSegment name="business-board-app">
		<AuthenticationCheck>
			<ProjectContextProvider>
				<ViewPreferencesProvider view="board" preferences={BOARD_VIEW_PREFERENCES}>
					<Board />
				</ViewPreferencesProvider>
			</ProjectContextProvider>
			<SubProductUpdater subProduct="core" />
		</AuthenticationCheck>
	</UFOSegment>
);

export default JiraBusinessBoardApp;

import React, { memo, useRef, useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { useResizingHeight } from '@atlaskit/motion';
import { token } from '@atlaskit/tokens';
import {
	generateMediaImageUrl,
	themeManager,
} from '@atlassian/jira-custom-theme-constants/src/utils.tsx';
import { useMediaConfiguration } from '@atlassian/jira-router-resources-business-project-details/src/index.tsx';
import { unsplashTracker } from '@atlassian/jira-unsplash/src/controllers/unsplash-tracker/index.tsx';
import { COLUMN_FIXED_WIDTH } from '../../../../../common/constants.tsx';
import type {
	BoardIssueCoverMedia,
	BoardIssueImageCoverMedia,
} from '../../../../../common/types.tsx';
import { useIsCardCoverEditingOpen } from '../../../../../controllers/card-cover-editing-state/index.tsx';

const CARD_COVER_COLOR_HEIGHT = 24;
const CARD_COVER_IMAGE_HEIGHT = 140;

type Props = {
	coverMedia: BoardIssueCoverMedia;
};

export const CardCover = memo(({ coverMedia }: { coverMedia: BoardIssueCoverMedia | null }) => {
	const isOpen = useIsCardCoverEditingOpen();

	const { ref } = useResizingHeight();

	return (
		<div ref={isOpen ? ref : null}>
			{coverMedia !== null && <CardCoverInner coverMedia={coverMedia} />}
		</div>
	);
});

const CardCoverInner = memo(({ coverMedia }: Props) => {
	if (coverMedia.type === 'color') {
		const theme = themeManager.getColor(coverMedia.value)?.theme;

		if (!theme) {
			return null;
		}

		return (
			<CardColorCoverContainer
				data-testid="work-management-board.ui.board.column.card.card-cover.card-cover-color"
				backgroundColor={theme.light['color.elevation.surface']}
			/>
		);
	}

	if (coverMedia.type === 'gradient') {
		const theme = themeManager.getGradient(coverMedia.value)?.theme;

		if (!theme) {
			return null;
		}

		return (
			<CardGradientCoverContainer
				data-testid="work-management-board.ui.board.column.card.card-cover.card-cover-gradient"
				background={theme.light['elevation.surface']}
			/>
		);
	}

	if (coverMedia.type === 'image') {
		return <CardImageCover coverMedia={coverMedia} />;
	}

	return null;
});

const CardImageCover = ({ coverMedia }: { coverMedia: BoardIssueImageCoverMedia }) => {
	const media = useMediaConfiguration();
	const imageRef = useRef<HTMLImageElement>(null);

	const imgUrl = useMemo(() => {
		if (!media?.clientId || !media?.externalEndpointUrl) {
			return null;
		}

		return generateMediaImageUrl({
			mediaApiFileId: coverMedia.mediaApiFileId,
			mediaClientId: media.clientId,
			mediaExternalEndpointUrl: media.externalEndpointUrl,
			token: coverMedia.mediaReadToken,
			mediaEndpoint: 'image',
			// A card is really 260px wide, but we want to load the image at a higher resolution
			// and then scale it down to avoid blurry images. If you use 260px, there will be a gap
			// between the image and the card border.
			width: COLUMN_FIXED_WIDTH,
			'max-age': Number.MAX_SAFE_INTEGER,
		});
	}, [coverMedia, media]);

	/**
	 * Give attribution to the unsplash api for qualified images.
	 * This is needed for legal reasons to comply with their API terms of service.
	 */
	useEffect(() => {
		if (!imgUrl) {
			return;
		}

		if (coverMedia.unsplashId != null && imageRef.current) {
			const unsubscribe = unsplashTracker.trackWhileInViewport(
				imageRef.current,
				coverMedia.unsplashId,
			);

			return unsubscribe;
		}
	}, [coverMedia.unsplashId, imgUrl]);

	if (!imgUrl) {
		return null;
	}

	return (
		<MemoizedCardImageCover
			ref={imageRef}
			data-testid="work-management-board.ui.board.column.card.card-cover.card-cover-image"
			src={imgUrl}
			title={coverMedia.fileName}
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardImageCoverImg = styled.div<{ src: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundImage: (props) => `url(${props.src})`,
	backgroundColor: token('color.background.neutral', 'white'),
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center',
	height: `${CARD_COVER_IMAGE_HEIGHT}px`,
	width: '100%',
	borderTopLeftRadius: token('border.radius', '4px'),
	borderTopRightRadius: token('border.radius', '4px'),
});

const urlWithoutToken = (url: string) => {
	const parsedUrl = new URL(url);
	parsedUrl.searchParams.delete('token');
	return parsedUrl.toString();
};

/**
 * Since the board items are refetched after any board changes, we receive a new token
 * every time. As a result, the image is refetched and causes a flicker. We don't need
 * to rerender the image in that case. The cases we need to are when the image is removed
 * or added, in which case we will retrieve a token separately and rerender.
 */
const MemoizedCardImageCover = memo(
	CardImageCoverImg,
	(prevProps, nextProps) => urlWithoutToken(prevProps.src) === urlWithoutToken(nextProps.src),
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardGradientCoverContainer = styled.div<{ background: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	background: (props) => props.background,
	height: CARD_COVER_COLOR_HEIGHT,
	width: '100%',
	borderTopLeftRadius: token('border.radius', '4px'),
	borderTopRightRadius: token('border.radius', '4px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CardColorCoverContainer = styled.div<{ backgroundColor: string }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: (props) => props.backgroundColor,
	height: CARD_COVER_COLOR_HEIGHT,
	width: '100%',
	borderTopLeftRadius: token('border.radius', '4px'),
	borderTopRightRadius: token('border.radius', '4px'),
});

import { useCallback, useMemo, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useCategoryField } from '@atlassian/jira-business-entity-project/src/controllers/category-field/index.tsx';
import type { Filters, QuickFilters } from '@atlassian/jira-business-filters/src/common/types.tsx';
import { useDeserialisedFilters } from '@atlassian/jira-business-filters/src/controllers/deserialise-filters/index.tsx';
import { useFilters } from '@atlassian/jira-business-filters/src/controllers/index.tsx';
import { useQuickFilters } from '@atlassian/jira-business-filters/src/controllers/quick-filters/index.tsx';
import { useSimpleSearch } from '@atlassian/jira-business-filters/src/controllers/simple-search/index.tsx';
import { isOptimisticIssue } from '@atlassian/jira-business-issue-create/src/controllers/issue-create-context/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import {
	useFieldsResource,
	type Field,
} from '@atlassian/jira-router-resources-business-fields/src/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import {
	BOARD_FIELD_CATEGORY,
	BOARD_FIELD_COMPONENTS,
	BOARD_FIELD_ISSUE_TYPE,
	BOARD_FIELD_LABEL,
	BOARD_FIELD_PRIORITY,
	BOARD_FIELD_SELECT,
	BOARD_FIELD_STATUS,
	BOARD_FIELD_USER,
	BOARD_FIELD_MULTI_USER,
	PARENT_ID,
} from '../../common/constants.tsx';
import type { BoardIssue } from '../../common/types.tsx';
import {
	filterByCategory,
	filterByComponents,
	filterByIssueType,
	filterByLabels,
	filterByPriority,
	filterBySelect,
	filterByStatus,
	filterByUser,
	filterByMultiUser,
	filterIssuesByText,
	getExpandedFilters,
	jqlTermToFieldId,
	isDueThisWeek,
	isAssignedToMe,
	type ExpandedFilter,
	filterByParent,
} from './utils.tsx';

const filterByExtendedFields = (issue: BoardIssue, expandedFilters: ExpandedFilter[]) =>
	expandedFilters.every((filter) => {
		// handle parent field filter separately as it searches in two fields parent and issue key
		if (filter.fieldKey === PARENT_ID && fg('jwm_board_implement_parent_filter')) {
			return filterByParent(filter, issue.fields);
		}
		const issueField = issue.fields[filter.fieldKey];
		if (issueField == null) {
			return filter.hasEmptyFilter;
		}

		switch (issueField.type) {
			case BOARD_FIELD_CATEGORY:
				return filterByCategory(filter, issueField);
			case BOARD_FIELD_COMPONENTS:
				return filterByComponents(filter, issueField);
			case BOARD_FIELD_ISSUE_TYPE:
				return filterByIssueType(filter, issueField);
			case BOARD_FIELD_LABEL:
				return filterByLabels(filter, issueField);
			case BOARD_FIELD_PRIORITY:
				return filterByPriority(filter, issueField);
			case BOARD_FIELD_SELECT:
				return filterBySelect(filter, issueField);
			case BOARD_FIELD_STATUS:
				return filterByStatus(filter, issueField);
			case BOARD_FIELD_USER:
				return filterByUser(filter, issueField);
			case BOARD_FIELD_MULTI_USER:
				return filterByMultiUser(filter, issueField);
			default:
				return false;
		}
	});

const filterIssues = (
	filters: Filters,
	issues: BoardIssue[],
	fields: Field[] | null,
): BoardIssue[] => {
	const expandedFilters = getExpandedFilters(filters, fields);

	return issues.filter(
		(issue) => isOptimisticIssue(issue.id) || filterByExtendedFields(issue, expandedFilters),
	);
};

const quickFilterIssues = (
	quickFilters: QuickFilters,
	issues: BoardIssue[],
	atlassianAccountId: string | null,
): BoardIssue[] => {
	let results = issues;
	if (quickFilters.ASSIGNED_TO_ME && atlassianAccountId != null) {
		results = isAssignedToMe(results, atlassianAccountId);
	}
	if (quickFilters.DUE_THIS_WEEK) {
		results = isDueThisWeek(results);
	}
	return results;
};

const EMPTY_FIELD_IDS: string[] = [];

export const useFilterFieldIds = (): string[] => {
	const { data: fields } = useFieldsResource();
	const { data: categoryField } = useCategoryField();
	const { filters } = useDeserialisedFilters();

	return useMemo(
		() =>
			filters != null && fields != null
				? Object.keys(filters)
						.map((fieldKeyFromFilter) =>
							jqlTermToFieldId(fieldKeyFromFilter, fields, categoryField),
						)
						.filter(Boolean)
				: EMPTY_FIELD_IDS,
		[categoryField, fields, filters],
	);
};

export const useFilterStatus = (): {
	isLoading: boolean;
	isFiltering: boolean;
} => {
	const { loading: fieldsLoading } = useFieldsResource();
	const { jql } = useFilters();
	const { quickFilters } = useQuickFilters();
	const [simpleSearch] = useSimpleSearch();
	const { isLoading: isDeserializingFilters } = useDeserialisedFilters();

	const isFiltering =
		!isEmpty(jql) ||
		quickFilters.ASSIGNED_TO_ME ||
		quickFilters.DUE_THIS_WEEK ||
		!isEmpty(simpleSearch);

	return {
		isLoading: isFiltering && (Boolean(isDeserializingFilters) || fieldsLoading),
		isFiltering,
	};
};

type FiltersDependencies = {
	simpleSearch: string;
	filters: Filters;
	quickFilters: QuickFilters;
};

const useMemoizedFiltersDependencies = (
	simpleSearch: string,
	filters: Filters,
	quickFilters: QuickFilters,
): FiltersDependencies => {
	const ref = useRef<FiltersDependencies>({
		simpleSearch,
		filters,
		quickFilters,
	});

	if (!isEqual(ref.current, { simpleSearch, filters, quickFilters })) {
		ref.current = { simpleSearch, filters, quickFilters };
	}

	return ref.current;
};

export const useFilter = (): ((issues: BoardIssue[]) => BoardIssue[]) => {
	const { data: fields } = useFieldsResource();
	const { quickFilters } = useQuickFilters();
	const [simpleSearch] = useSimpleSearch();
	const { filters } = useDeserialisedFilters();
	const { atlassianAccountId } = useTenantContext();

	// these dependencies are memoized with deep equality comparison because they are not stable
	const filtersDependencies: FiltersDependencies = useMemoizedFiltersDependencies(
		simpleSearch,
		filters,
		quickFilters,
	);

	const filter = useCallback(
		(issues: BoardIssue[]) => {
			// we always want to filter issues with text search
			const results = filterIssuesByText(issues, filtersDependencies.simpleSearch);

			if (!isEmpty(filtersDependencies.filters)) {
				return filterIssues(filtersDependencies.filters, results, fields);
			}

			return quickFilterIssues(filtersDependencies.quickFilters, results, atlassianAccountId);
		},
		[filtersDependencies, fields, atlassianAccountId],
	);

	return filter;
};

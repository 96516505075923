import React, { memo, useState, type RefObject } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import ArrowRightIcon from '@atlaskit/icon/glyph/arrow-right';
import CollapseIcon from '@atlaskit/icon/glyph/editor/collapse';
import ExpandIcon from '@atlaskit/icon/glyph/editor/expand';
import { Box, xcss } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { GROUP_BY_STATUS } from '@atlassian/jira-business-constants/src/index.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import {
	useIsWorkflowOperationInProgress,
	useWorkflowOperationStatusId,
} from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import StatusLozenge from '@atlassian/jira-common-components-status-lozenge/src/view.tsx';
import { statusCategoryForId } from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { Z_INDEX, COLUMN_HEADER_HEIGHT } from '../../../../common/constants.tsx';
import type { Group, StatusGroup } from '../../../../common/types.tsx';
import RestrictedIcon from '../../../../common/ui/restricted-icon/index.tsx';
import { useCardDragDrop } from '../../../../controllers/drag-and-drop/card-drag-drop-context/index.tsx';
import { useExpandCollapse } from '../../../../controllers/expand-collapse-columns/index.tsx';
import { useWorkflowStoreState } from '../../../../controllers/workflow-store/index.tsx';
import ColumnConfetti from './column-confetti/index.tsx';
import HeaderContent from './header-content/index.tsx';
import HeaderDropdown from './header-dropdown/index.tsx';
import messages from './messages.tsx';

const renderTransitionInfoOverlay = (sourceGroup: StatusGroup, targetGroup: StatusGroup) => (
	<TransitionInfoContainer>
		<StatusLozenge
			category={statusCategoryForId(sourceGroup.statusCategoryId)}
			name={sourceGroup.name}
		/>

		<ArrowRightIcon size="small" label="" />

		<StatusLozenge
			category={statusCategoryForId(targetGroup.statusCategoryId)}
			name={targetGroup.name}
		/>
	</TransitionInfoContainer>
);

export type Props = {
	dragHandleRef?: RefObject<HTMLElement>;
	group: Group;
	isCardOverColumn: boolean;
	isDragPreview: boolean;
	issueCount: number;
	isDropDisabled: boolean;
};

const ColumnHeader = ({
	dragHandleRef,
	group,
	isCardOverColumn,
	isDragPreview,
	isDropDisabled,
	issueCount,
}: Props) => {
	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();
	const workflowOperationStatusId = useWorkflowOperationStatusId();
	const { formatMessage } = useIntl();

	const {
		sourceGroup,
		dropTarget: { transition },
	} = useCardDragDrop();
	const { isCollapsed, toggleCollapse } = useExpandCollapse(group.id);
	const [isEditMode, setEditMode] = useState(false);

	const project = useProject();
	const { workflows } = useWorkflowStoreState();
	const hasMultipleWorkflows = workflows.length > 1;
	const isTeamManaged = project.isSimplified;
	const isProjectAdmin = project.permissions.administerProject;

	const shouldShowTransitionInfoOverlay =
		transition != null &&
		sourceGroup?.type === GROUP_BY_STATUS &&
		group.type === GROUP_BY_STATUS &&
		isCardOverColumn &&
		!isCollapsed;

	const isCollapsedDropNotAllowed = isDropDisabled && isCollapsed;

	return (
		<Container
			data-testid="work-management-board.ui.board.column.column-header.container"
			isColumnCollapsed={isCollapsed}
		>
			<HeaderTitleWrapper
				data-testid="work-management-board.ui.board.column.column-header.header-title-wrapper"
				isColumnCollapsed={isCollapsed}
			>
				{isCollapsedDropNotAllowed ? (
					<RestrictedIcon />
				) : (
					isProjectAdmin && (
						<Box
							aria-hidden
							xcss={dragHandleStyles}
							ref={dragHandleRef}
							testId="work-management-board.ui.board.column.column-header.drag-handle"
						>
							⠿
						</Box>
					)
				)}
				{shouldShowTransitionInfoOverlay ? (
					renderTransitionInfoOverlay(sourceGroup, group)
				) : (
					<HeaderContent
						group={group}
						isCollapsed={isCollapsed}
						issueCount={issueCount}
						isEditMode={isEditMode}
						setEditMode={setEditMode}
						hasMultipleWorkflows={hasMultipleWorkflows}
					/>
				)}
				{isCollapsed ? (
					<ExpandCollapseButtonWrapper data-component-selector="work-management-board.ui.board.column.column-header.expand-button-wrapper">
						<Button
							appearance="subtle"
							iconBefore={<ExpandIcon label={formatMessage(messages.expandButton)} size="medium" />}
							onClick={toggleCollapse}
							spacing="none"
						/>
					</ExpandCollapseButtonWrapper>
				) : (
					<MenuWrapper>
						<ExpandCollapseButtonWrapper data-component-selector="work-management-board.ui.board.column.column-header.collapse-button-wrapper">
							<Button
								appearance="subtle"
								iconBefore={
									<CollapseIcon label={formatMessage(messages.collapseButton)} size="medium" />
								}
								onClick={toggleCollapse}
							/>
						</ExpandCollapseButtonWrapper>

						{isTeamManaged && group.type === GROUP_BY_STATUS && !isDragPreview && (
							<HeaderDropdown
								isLoading={workflowOperationStatusId === group.id && isWorkflowOperationInProgress}
								hasMultipleWorkflows={hasMultipleWorkflows}
								columnName={group.name}
								onRenameClick={() => {
									setEditMode(true);
								}}
							/>
						)}

						<ColumnConfetti group={group} />
					</MenuWrapper>
				)}
			</HeaderTitleWrapper>
		</Container>
	);
};

type HeaderTitleWrapperProps = {
	isColumnCollapsed: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const HeaderTitleWrapper = styled.div<HeaderTitleWrapperProps>({
	alignItems: 'center',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.background.neutral.bold', colors.N600),
	display: 'flex',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	flexDirection: ({ isColumnCollapsed }) => (isColumnCollapsed ? 'column' : 'row'),
	gap: token('space.050', '4px'),
	justifyContent: 'space-between',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isColumnCollapsed }) =>
		isColumnCollapsed
			? `14px 0 ${token('space.050', '4px')}`
			: `0 ${token('space.100', '8px')} 0 ${token('space.200', '16px')}`,
	width: '100%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExpandCollapseButtonWrapper = styled.div({
	display: 'flex',
	height: 'var(--expand-collapse-button-height)',
	opacity: 'var(--expand-collapse-button-opacity)',
	width: 'var(--expand-collapse-button-width)',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{
	isColumnCollapsed: boolean;
}>(
	{
		display: 'flex',
		position: 'relative',
		userSelect: 'none',

		'--expand-collapse-button-height': '0px',
		'--expand-collapse-button-opacity': 0,
		'--expand-collapse-button-width': '0px',
		'&:hover, &:focus-within': {
			'--expand-collapse-button-height': 'auto',
			'--expand-collapse-button-opacity': 1,
			'--expand-collapse-button-width': 'auto',
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	({ isColumnCollapsed }) =>
		isColumnCollapsed
			? {
					height: 'auto',
					minHeight: 0,
					flexShrink: 1,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					zIndex: Z_INDEX.collapsedStickyHeaders,
				}
			: {
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					height: `${COLUMN_HEADER_HEIGHT}px`,
					flexShrink: 0,
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					zIndex: Z_INDEX.stickyHeaders,
				},
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TransitionInfoContainer = styled.div({
	alignItems: 'center',
	display: 'grid',
	gridAutoFlow: 'column',
	justifyContent: 'left',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& span': {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MenuWrapper = styled.div({
	display: 'flex',
	flex: 'none',
});

const dragHandleStyles = xcss({
	color: 'color.text.subtle',
	cursor: 'grab',
});

export default memo(ColumnHeader);

import React, { memo } from 'react';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/main.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazySignupInviteModal = lazy(
	() =>
		import(/* webpackChunkName: "async-jira-business-signup-invite-modal" */ './main').then(
			({ SignupInviteModal }) => SignupInviteModal,
		),
	{ ssr: false },
);

export const SignupInviteModal = memo(() => (
	<ErrorBoundary
		id="jira-business.signup-invite-modal.modal"
		packageName="jira-business.signup-invite-modal"
	>
		<Placeholder name="signup-invite-modal" fallback={null}>
			<LazySignupInviteModal />
		</Placeholder>
	</ErrorBoundary>
));

import React, { useState, useCallback, useMemo, memo } from 'react';
import { styled } from '@compiled/react';
import { IconButton } from '@atlaskit/button/new';
import EditorAddIcon from '@atlaskit/icon/core/add';
import EditorAddIconLegacy from '@atlaskit/icon/glyph/editor/add';
import InlineDialog from '@atlaskit/inline-dialog';
import { N10 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { createColumnEntryPoint } from '@atlassian/jira-business-create-status-modal/entrypoint.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { TEMPORARY_STATUS_ID } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/constants.tsx';
import { useCreateStatus } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/create-status/index.tsx';
import { useIsWorkflowOperationInProgress } from '@atlassian/jira-business-workflows/src/controllers/workflow-actions/index.tsx';
import type { RuntimePropsOfEntryPoint } from '@atlassian/jira-entry-point/src/common/types.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { ModalEntryPointIconButtonTrigger } from '@atlassian/jira-modal-entry-point-icon-button-trigger/src/ModalEntryPointIconButtonTrigger.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import { COLUMN_ACTION_ADD_EXPERIENCE } from '../../../common/constants.tsx';
import { ContactAdminToModifyBoardMessage } from '../../../common/ui/contact-admin-to-modify-board/index.tsx';
import { OpenProjectSettingsMultipleWorkflows } from '../../../common/ui/open-project-settings-multiple-workflows/index.tsx';
import { useBoardData } from '../../../controllers/board-data/index.tsx';
import { useGroups } from '../../../controllers/groups-context/index.tsx';
import {
	useWorkflowStoreState,
	useWorkflowStoreActions,
} from '../../../controllers/workflow-store/index.tsx';
import messages from './messages.tsx';

type CreateStatusModalProps = RuntimePropsOfEntryPoint<typeof createColumnEntryPoint>;

const ColumnCreate = () => {
	const { workflows, issueTypes } = useWorkflowStoreState();
	const isWorkflowOperationInProgress = useIsWorkflowOperationInProgress();
	const issueTypeId = issueTypes[0]?.id;
	const groups = useGroups();
	const existingColumnNames = useMemo(() => groups.map(({ name }) => name), [groups]);

	const { formatMessage } = useIntl();

	const [isNoPermissionMessageOpen, setIsNoPermissionMessageOpen] = useState<boolean | undefined>();
	const [isMultipleWorkflowsMessageOpen, setIsMultipleWorkflowsMessageOpen] = useState<
		boolean | undefined
	>();

	const createNewStatus = useCreateStatus(COLUMN_ACTION_ADD_EXPERIENCE);

	const { removeColumn } = useWorkflowStoreActions();

	const removeTemporaryColumn = useCallback(
		() => removeColumn(TEMPORARY_STATUS_ID),
		[removeColumn],
	);

	const { refetch: refetchBoardData } = useBoardData();

	const project = useProject();
	const canAdministerProject = project.permissions.administerProject;
	const hasMultipleWorkflows = workflows.length > 1;

	const validateStatus: CreateStatusModalProps['validateStatus'] = useCallback(
		({ statusName }) =>
			!existingColumnNames.some(
				(existingColumnName) => existingColumnName.toUpperCase() === statusName.toUpperCase(),
			),
		[existingColumnNames],
	);

	const createNewColumn = useCallback(
		async (payload: Parameters<typeof createNewStatus>[0]) => {
			try {
				await createNewStatus(payload);
			} catch (error) {
				removeTemporaryColumn();
				throw error;
			} finally {
				refetchBoardData();
			}
		},
		[createNewStatus, removeTemporaryColumn, refetchBoardData],
	);

	const createColumnModalProps = useMemo(() => {
		return {
			createStatus: createNewColumn,
			validateStatus,
			issueTypeId,
		};
	}, [createNewColumn, issueTypeId, validateStatus]);

	if (!canAdministerProject) {
		// show button that will trigger no permission message for user without permission
		return renderColumnCreateButtonWithMessage({
			isOpen: Boolean(isNoPermissionMessageOpen),
			setIsOpen: setIsNoPermissionMessageOpen,
			testId: 'work-management-board.ui.board.column-create.no-permission-button',
			label: formatMessage(messages.createStatusLabel),
			analyticsId: 'jwmColumnCreateNoPermission',
			MessageComponent: ContactAdminToModifyBoardMessage,
		});
	}

	if (hasMultipleWorkflows) {
		return renderColumnCreateButtonWithMessage({
			isOpen: Boolean(isMultipleWorkflowsMessageOpen),
			setIsOpen: setIsMultipleWorkflowsMessageOpen,
			testId: 'work-management-board.ui.board.column-create.has-multiple-workflows-button',
			label: formatMessage(messages.createStatusLabel),
			analyticsId: 'jwmColumnCreateMultipleWorkflows',
			MessageComponent: OpenProjectSettingsMultipleWorkflows,
		});
	}

	if (!issueTypeId) {
		return null;
	}

	const button = (
		<ModalEntryPointIconButtonTrigger
			entryPoint={createColumnEntryPoint}
			entryPointProps={createColumnModalProps}
			interactionName="jwmColumnCreate"
			appearance="subtle"
			testId="work-management-board.ui.board.column-create.create-column-button"
			icon={() => (
				<EditorAddIcon
					label=""
					spacing="spacious"
					color="currentColor"
					LEGACY_fallbackIcon={EditorAddIconLegacy}
				/>
			)}
			isDisabled={isWorkflowOperationInProgress}
			label={formatMessage(messages.createStatusLabel)}
			useInternalModal={false}
		/>
	);
	return (
		<>
			<SurfaceWrapper>
				<Tooltip content={formatMessage(messages.createStatusLabel)}>{button}</Tooltip>
			</SurfaceWrapper>
		</>
	);
};

export default memo(ColumnCreate);

const renderColumnCreateButtonWithMessage = ({
	isOpen,
	setIsOpen,
	testId,
	label,
	analyticsId,
	MessageComponent,
}: {
	isOpen: boolean;
	setIsOpen: (state: boolean) => void;
	testId: string;
	label: string;
	analyticsId: string;
	MessageComponent: () => React.JSX.Element;
}) => (
	<InlineDialog
		content={
			<MessageWrapper>
				<MessageComponent />
			</MessageWrapper>
		}
		isOpen={isOpen}
		onClose={() => {
			setIsOpen(false);
		}}
	>
		<SurfaceWrapper>
			<IconButton
				icon={EditorAddIcon}
				label={label}
				testId={testId}
				onClick={(_, analyticsEvent) => {
					setIsOpen(true);
					fireUIAnalytics(analyticsEvent, analyticsId);
				}}
			/>
		</SurfaceWrapper>
	</InlineDialog>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SurfaceWrapper = styled.div({
	borderRadius: token('border.radius', '3px'),
	backgroundColor: token('elevation.surface.sunken', N10),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MessageWrapper = styled.div({
	width: '200px',
});

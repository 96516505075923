import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { useProject } from '@atlassian/jira-business-entity-project-hook/src/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { Group } from '../../../common/types.tsx';

export const useHasDragPermission = (groupType: Group['type']) => {
	const { permissions } = useProject();
	const hasRankPermission = permissions.editIssues && permissions.scheduleIssues;

	switch (groupType) {
		// @ts-expect-error TS7029 - expected fallthrough in switch case for now
		case GROUP_BY_ASSIGNEE: {
			if (fg('fun-1216_fix_board_assign_issue_permissions')) {
				return hasRankPermission || permissions.assignIssues;
			}
		}
		// fallthrough
		case GROUP_BY_CATEGORY:
		case GROUP_BY_PRIORITY:
			return permissions.editIssues;
		case GROUP_BY_STATUS: {
			return hasRankPermission || permissions.transitionIssues;
		}
		default: {
			const exhaustiveCheck: never = groupType;
			throw new Error(`Unexpected groupBy value: ${exhaustiveCheck}`);
		}
	}
};

import React, { useMemo } from 'react';
import noop from 'lodash/noop';
import { isPageVisible } from '@atlassian/jira-common-page-visibility/src/index.tsx';
import {
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_UPDATED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
} from '@atlassian/jira-realtime/src/common/constants/events.tsx';
import type { RealtimeEvent } from '@atlassian/jira-realtime/src/common/types/events.tsx';
import { buildChannelString } from '@atlassian/jira-realtime/src/common/utils/pubsub.utils.tsx';
import Realtime from '@atlassian/jira-realtime/src/main.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useRealtimeContext } from '../../controllers/realtime-context/index.tsx';

type Props = {
	appId: string;
	eventsToSubscribeTo?: string[];
	onReceive: (arg1: RealtimeEvent) => void;
};

const DEFAULT_EVENTS_TO_SUBSCRIBE_TO = [
	ISSUE_CREATED_EVENT,
	ISSUE_DELETED_EVENT,
	ISSUE_UPDATED_EVENT,
	ISSUE_TRANSITIONED_EVENT,
];

/**
 * Wrapper on top of Realtime component for business project
 * This contains additional logging and analytics behaviour
 * As well as debouncing behaviour when receiving an event
 * This component will not call the callback if the current browser tab is not visible
 */
export const BusinessRealtime = ({
	appId,
	onReceive,
	eventsToSubscribeTo = DEFAULT_EVENTS_TO_SUBSCRIBE_TO,
}: Props) => {
	const cloudId = useCloudId();
	const realtimeIds = useRealtimeContext();

	const realtimeChannels = useMemo(
		() => realtimeIds.map((pid) => buildChannelString(cloudId, pid)),
		[cloudId, realtimeIds],
	);

	return (
		<Realtime
			appId={appId}
			channels={realtimeChannels}
			events={eventsToSubscribeTo}
			onJoin={noop}
			onReceive={(event: RealtimeEvent) => {
				if (!isPageVisible()) {
					return;
				}

				onReceive(event);
			}}
			onLeave={noop}
		/>
	);
};

import { useMemo } from 'react';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { useIssueTypesAndFields } from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/index.tsx';
import type {
	BusinessIssueField,
	BusinessSelectOption,
} from '@atlassian/jira-business-entity-project/src/services/issue-types-and-fields/types.tsx';
import {
	StatusCategoryIds,
	type StatusCategoryKeys,
} from '@atlassian/jira-common-constants/src/status-categories.tsx';
import { CATEGORY_TYPE, PRIORITY_TYPE } from '@atlassian/jira-platform-field-config/src/index.tsx';
import { NULL_GROUP_KEY } from '../../common/constants.tsx';
import type {
	AssigneeGroup,
	CategoryGroup,
	Group,
	PriorityGroup,
	StatusGroup,
} from '../../common/types.tsx';
import { useIssueAssignees } from '../board-issue-store/selectors/index.tsx';
import { useSortGroups } from '../groups-order/index.tsx';
import { useWorkflowStoreState } from '../workflow-store/index.tsx';

const getPriorityOptions = (fields: BusinessIssueField[]) => {
	const priorityField = fields.find((field) => field.type === PRIORITY_TYPE);
	return priorityField?.fieldConfig?.options ?? [];
};

const getCategoryOptions = (fields: BusinessIssueField[]): BusinessSelectOption[] => {
	const categoryField = fields.find((field) => field.type === CATEGORY_TYPE);
	return categoryField?.fieldConfig?.selectOptions ?? [];
};

export const useAssigneeGroups = (): Group[] => {
	const users = useIssueAssignees();
	const sortGroups = useSortGroups();

	return useMemo(() => {
		const groups: AssigneeGroup[] = [
			{
				id: NULL_GROUP_KEY,
				imageUrl: null,
				name: '',
				type: GROUP_BY_ASSIGNEE,
			},
		];

		users?.forEach(({ accountId, name, avatarURL }) => {
			groups.push({
				id: accountId,
				imageUrl: avatarURL,
				name,
				type: GROUP_BY_ASSIGNEE,
			});
		});

		return sortGroups(groups);
	}, [sortGroups, users]);
};

export const useCategoryGroups = (): Group[] => {
	const {
		data: { fields },
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});
	const sortGroups = useSortGroups();

	return useMemo(() => {
		const groups: CategoryGroup[] = [
			{
				color: null,
				id: NULL_GROUP_KEY,
				name: '',
				type: GROUP_BY_CATEGORY,
			},
		];

		const categoryOptions = getCategoryOptions(fields);
		categoryOptions.forEach(({ label, color, optionId }) => {
			if (optionId) {
				groups.push({
					color: color ?? null,
					id: String(optionId),
					name: label,
					type: GROUP_BY_CATEGORY,
				});
			}
		});

		return sortGroups(groups);
	}, [fields, sortGroups]);
};

export const usePriorityGroups = (): Group[] => {
	const {
		data: { fields },
	} = useIssueTypesAndFields({
		issueOperation: 'VIEW',
	});
	const sortGroups = useSortGroups();

	return useMemo(() => {
		const priorityOptions = getPriorityOptions(fields);
		const groups: PriorityGroup[] = priorityOptions.map(({ name, priorityId, iconUrl }) => ({
			id: priorityId,
			imageUrl: iconUrl,
			name,
			type: GROUP_BY_PRIORITY,
		}));

		return sortGroups(groups);
	}, [fields, sortGroups]);
};

export const useStatusGroups = (): StatusGroup[] => {
	const { columns } = useWorkflowStoreState();

	return useMemo(() => {
		const groups: StatusGroup[] = columns.map(({ name, statusId, statusCategory }) => ({
			id: String(statusId),
			name,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			statusCategoryId: StatusCategoryIds[statusCategory as StatusCategoryKeys],

			type: GROUP_BY_STATUS,
		}));

		return groups;
	}, [columns]);
};

import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

export const configurationModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() =>
			import(
				/* webpackChunkName: "async-jira-configuration-modal-experience" */ './src/ui/configuration-modal'
			),
	),
	getPreloadProps: () => ({}),
});

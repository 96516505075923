import React, { createContext, useContext, useMemo, type ReactNode } from 'react';
import {
	GROUP_BY_ASSIGNEE,
	GROUP_BY_CATEGORY,
	GROUP_BY_PRIORITY,
	GROUP_BY_STATUS,
} from '@atlassian/jira-business-constants/src/index.tsx';
import { NULL_GROUP_KEY } from '../../common/constants.tsx';
import type { BoardIssue, Group } from '../../common/types.tsx';
import { useGroupByField } from '../group-by/index.tsx';
import { useGroups } from '../groups-context/index.tsx';
import { useSortGroups } from '../groups-order/index.tsx';

const EMPTY_ISSUES: BoardIssue[] = [];

type IssuesByGroup = Map<Group['id'], BoardIssue[]>;

const RootContext = createContext<IssuesByGroup | null>(null);

type RootProviderProps = {
	filteredIssues: BoardIssue[];
	children: ReactNode;
};

export const IssuesByGroupProvider = ({ filteredIssues, children }: RootProviderProps) => {
	const groupBy = useGroupByField();

	const issuesByGroup = useMemo(() => {
		const map = new Map<Group['id'], BoardIssue[]>();

		// iterate over the filtered issues and add them to the correct group
		filteredIssues.forEach((issue) => {
			const groupId = issue.fields[groupBy]?.value ?? NULL_GROUP_KEY;
			let groupIssues = map.get(groupId);
			if (!groupIssues) {
				groupIssues = [];
				map.set(groupId, groupIssues);
			}
			groupIssues.push(issue);
		});

		return map;
	}, [filteredIssues, groupBy]);

	return <RootContext.Provider value={issuesByGroup}>{children}</RootContext.Provider>;
};

const useIssuesByGroup = (): IssuesByGroup => {
	const issuesByGroup = useContext(RootContext);

	if (!issuesByGroup) {
		throw new Error('useIssuesByGroup must be used within a IssuesByGroupProvider');
	}

	return issuesByGroup;
};

export const useIssueGroups = (): Group[] => {
	// merge the groups from the group context with the groups from the issues by group
	// to make sure we do not hide any groups
	const groupBy = useGroupByField();
	const groups = useGroups();
	const issuesByGroup = useIssuesByGroup();
	const sortGroups = useSortGroups();

	return useMemo(() => {
		// if grouped by status or assignee, skip this
		if (groupBy === GROUP_BY_STATUS || groupBy === GROUP_BY_ASSIGNEE) {
			return groups;
		}

		// if all the groups found in issuesByGroup are already in the groups array
		// we can just return the groups array
		const missingGroups = [...issuesByGroup.keys()].filter(
			(groupId) => !groups.some((group) => group.id === groupId),
		);

		if (missingGroups.length === 0) {
			return groups;
		}

		return sortGroups([
			...missingGroups.map((groupId) => {
				const firstIssue = issuesByGroup.get(groupId)?.[0];

				if (!firstIssue) {
					throw new Error('No issues found for group');
				}

				switch (groupBy) {
					case GROUP_BY_PRIORITY:
						return {
							id: groupId,
							imageUrl: firstIssue.fields[groupBy]?.priority?.iconUrl ?? null,
							name: firstIssue.fields[groupBy]?.priority.name ?? '',
							type: groupBy,
						};
					case GROUP_BY_CATEGORY:
						return {
							id: groupId,
							color: firstIssue.fields[groupBy]?.category?.color ?? null,
							name: firstIssue.fields[groupBy]?.category.name ?? '',
							type: groupBy,
						};
					default: {
						const exhaustiveCheck: never = groupBy;
						throw new Error(`Unexpected groupBy value: ${exhaustiveCheck}`);
					}
				}
			}),
			...groups,
		]);
	}, [sortGroups, groupBy, groups, issuesByGroup]);
};

type ChildContext = {
	issues: BoardIssue[];
	group: Group;
};

const ChildContext = createContext<ChildContext | null>(null);

type ChildProviderProps = { group: Group; children: ReactNode };

export const IssueGroupProvider = ({ group, children }: ChildProviderProps) => {
	const issuesByGroup = useIssuesByGroup();

	const issues = issuesByGroup.get(group.id) ?? EMPTY_ISSUES;

	const value = useMemo(() => ({ issues, group }), [issues, group]);

	return <ChildContext.Provider value={value}>{children}</ChildContext.Provider>;
};

export const useIssueGroup = (): ChildContext => {
	const issueGroup = useContext(ChildContext);

	if (!issueGroup) {
		throw new Error('useIssueGroup must be used within a IssueGroupProvider');
	}

	return issueGroup;
};

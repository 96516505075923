import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	errorTitle: {
		id: 'work-management-board.controllers.issue-transitions.error-title',
		defaultMessage: "We couldn't load transitions",
		description:
			'Error message title informing the user that we were unable to load transitions for the issue',
	},
	errorMessage: {
		id: 'work-management-board.controllers.issue-transitions.error-message',
		defaultMessage: 'Check your connection, then give it another try.',
		description:
			'Error message description informing the user that we were unable to load transitions for the issue',
	},
	notFoundErrorTitle: {
		id: 'work-management-board.controllers.issue-transitions.not-found-error-title',
		defaultMessage: 'Issue not found',
		description: 'Error message title informing the user that the issue is not found',
	},
	notFoundErrorMessage: {
		id: 'work-management-board.controllers.issue-transitions.not-found-error-message',
		defaultMessage: 'Looks like this issue has been removed. Refresh the page, then try again.',
		description: 'Error message description informing the user that the issue is not found',
	},
	notFoundErrorTitleIssueTermRefresh: {
		id: 'work-management-board.controllers.issue-transitions.not-found-error-title-issue-term-refresh',
		defaultMessage: 'Issue not found',
		description: 'Error message title informing the user that the issue is not found',
	},
	notFoundErrorMessageIssueTermRefresh: {
		id: 'work-management-board.controllers.issue-transitions.not-found-error-message-issue-term-refresh',
		defaultMessage: 'Looks like this issue has been removed. Refresh the page, then try again.',
		description: 'Error message description informing the user that the issue is not found',
	},
});

import { useCallback } from 'react';
import difference from 'lodash/difference';
import { ISSUE_TYPE_ID } from '../../common/constants.tsx';
import { useFetchIssueDetails } from '../board-issue-details/index.tsx';
import { useIssueStoreActions } from '../board-issue-store/index.tsx';
import { useIssueTypeIds } from '../workflow-store/index.tsx';

export const useRefetchIssues = () => {
	const refetchIssuesWithThrow = useRefetchIssuesWithThrow();

	return useCallback(
		async (issueIds: number[]) => {
			try {
				return await refetchIssuesWithThrow(issueIds);
			} catch (error) {
				// do nothing
				return [];
			}
		},
		[refetchIssuesWithThrow],
	);
};

/** Does not catch the errors and throws them to the consumer */
export const useRefetchIssuesWithThrow = () => {
	const fetchIssueDetails = useFetchIssueDetails();
	const { setIssues, removeIssues } = useIssueStoreActions();
	const issueTypeIds = useIssueTypeIds();

	return useCallback(
		async (issueIds: number[]) => {
			const fetchedIssues = await fetchIssueDetails(issueIds);

			// do not add issues that are not in the current workflow
			const issues = fetchedIssues.filter((issue) => {
				const issueTypeId = issue.fields[ISSUE_TYPE_ID].issueType.id;

				return issueTypeIds.has(issueTypeId);
			});

			setIssues(issues);

			const fetchedIssueIds = issues.map((issue) => issue.id);
			const removedIssueIds = difference(issueIds, fetchedIssueIds);

			if (removedIssueIds.length > 0) {
				removeIssues(removedIssueIds);
			}

			return issues;
		},
		[fetchIssueDetails, setIssues, issueTypeIds, removeIssues],
	);
};

import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	unableToUpdateAssigneeMessage: {
		id: 'work-management-board.board.column.invalid-drop-target-message.unable-to-update-assignee-message',
		defaultMessage:
			"You can't move this issue to this column because the assignee field is not associated with this issue type.",
		description:
			'The message shown in a board column when the card being dragged cannot be moved to another column due to the assignee field not being associated with the issue type.',
	},
	unableToUpdateCategoryMessage: {
		id: 'work-management-board.board.column.invalid-drop-target-message.unable-to-update-category-message',
		defaultMessage:
			"You can't move this issue to this column because the category field is not associated with this issue type.",
		description:
			'The message shown in a board column when the card being dragged cannot be moved to another column due to the category field not being associated with the issue type.',
	},
	unableToUpdatePriorityMessage: {
		id: 'work-management-board.board.column.invalid-drop-target-message.unable-to-update-priority-message',
		defaultMessage:
			"You can't move this issue to this column because the priority field is not associated with this issue type.",
		description:
			'The message shown in a board column when the card being dragged cannot be moved to another column due to the priority field not being associated with the issue type.',
	},
	transitionNotAvailableMessage: {
		id: 'work-management-board.board.column.invalid-drop-target-message.transition-not-available-message',
		defaultMessage:
			"You can't move this issue to this column due to workflow configurations or permissions.",
		description:
			'The message shown in a board column when there are no applicable transitions for the card being dragged when grouping by status.',
	},
	unableToUpdateAssigneeMessageIssueTermRefresh: {
		id: 'work-management-board.board.column.invalid-drop-target-message.unable-to-update-assignee-message-issue-term-refresh',
		defaultMessage:
			"You can't move this issue to this column because the assignee field is not associated with this issue type.",
		description:
			'The message shown in a board column when the card being dragged cannot be moved to another column due to the assignee field not being associated with the issue type.',
	},
	unableToUpdateCategoryMessageIssueTermRefresh: {
		id: 'work-management-board.board.column.invalid-drop-target-message.unable-to-update-category-message-issue-term-refresh',
		defaultMessage:
			"You can't move this issue to this column because the category field is not associated with this issue type.",
		description:
			'The message shown in a board column when the card being dragged cannot be moved to another column due to the category field not being associated with the issue type.',
	},
	unableToUpdatePriorityMessageIssueTermRefresh: {
		id: 'work-management-board.board.column.invalid-drop-target-message.unable-to-update-priority-message-issue-term-refresh',
		defaultMessage:
			"You can't move this issue to this column because the priority field is not associated with this issue type.",
		description:
			'The message shown in a board column when the card being dragged cannot be moved to another column due to the priority field not being associated with the issue type.',
	},
	transitionNotAvailableMessageIssueTermRefresh: {
		id: 'work-management-board.board.column.invalid-drop-target-message.transition-not-available-message-issue-term-refresh',
		defaultMessage:
			"You can't move this issue to this column due to workflow configurations or permissions.",
		description:
			'The message shown in a board column when there are no applicable transitions for the card being dragged when grouping by status.',
	},
});
